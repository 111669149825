import axios from '../../helpers/private.axios';
import { notify } from '../common/notify.actions';

import { getApplicationInfo, searchAgreementApplications } from '../index'

export const UPLOAD_AGREEMENT_REQ = "UPLOAD_AGREEMENT_REQ";
export const UPLOAD_AGREEMENT_RES = "UPLOAD_AGREEMENT_RES";
export const UPLOAD_AGREEMENT_ERR = "UPLOAD_AGREEMENT_ERR";

export const uploadAgreement = (  application_id,formData, history ) => (dispatch) => {
    dispatch({ type: UPLOAD_AGREEMENT_REQ, application_id, formData, history });
    axios.post(`/api/reporting/${application_id}/agreement`, formData )
    .then( res => {
        dispatch({ type: UPLOAD_AGREEMENT_RES, payload: res.data.data });
        // dispatch( getApplicationInfo(application_id) );
        // dispatch( 
        //     notify( res.data.data.message, "success" )
        // );
        // history.push(`/rp/application/${application_id}/matching`)
    })

};

export const uploadFile = (  application_id, file, file_type, additional_id, history ) => (dispatch) => {
    console.log( file );

    let formData = new FormData();
    formData.append('type', file_type );    
    formData.append('file', file );    
    formData.append('additional_id', additional_id );    

    dispatch({ type: UPLOAD_AGREEMENT_REQ, application_id, file, file_type, history });

    axios.post(`/api/reporting/${application_id}/file`, formData )
    .then( res => {
        dispatch({ type: UPLOAD_AGREEMENT_RES, payload: res.data.data });

        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
    })
}


export const END_AGREEMENT_REQ = "END_AGREEMENT_REQ";
export const END_AGREEMENT_RES = "END_AGREEMENT_RES";
export const END_AGREEMENT_ERR = "END_AGREEMENT_ERR";

export const endAgreement = ( application_id, history ) => dispatch => {
    dispatch({ type: END_AGREEMENT_REQ });

    axios.post(`/api/reporting/${application_id}/agreement/end`)
    .then( res => {
        dispatch({ type: UPLOAD_AGREEMENT_RES, payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application_id}/matching`)
    }) 
}

export const endAct = ( application_id, history ) => dispatch => {
    dispatch({ type: END_AGREEMENT_REQ });

    axios.post(`/api/reporting/${application_id}/act/end`)
    .then( res => {
        dispatch({ type: UPLOAD_AGREEMENT_RES, payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application_id}/matching`)
    }) 
}

export const signAct = (application_id, history) => (dispatch) => {
    dispatch({ type: 'SIGN_ACT_REQ' });
    axios.post(`/api/reporting/${application_id}/signact`)
    .then( res => {
        dispatch({ type: 'SIGN_ACT_RES', payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application_id}/matching`)
    }) 
}



export const signGrunt = (application_id, history) => (dispatch) => {
    console.log('action GRUNT',)
    dispatch({ type: 'SIGN_GRUNT_REQ' });
    axios.post(`/api/reporting/${application_id}/signgrunt`)
    .then( res => {
        dispatch({ type: 'SIGN_GRUNT_RES', payload: res.data.data });
        dispatch( getApplicationInfo(application_id) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        history.push(`/rp/application/${application_id}/matching`)
    });   
}


export const signAdditional = ( application_id, additional_agreement_id ) => (dispatch) => {
    dispatch({ type: 'SIGN_GRUNT_REQ' });
        axios.post(`/api/reporting/${application_id}/additional-sign/${additional_agreement_id}`)
        .then( res => {
            dispatch({ type: 'SIGN_GRUNT_RES', payload: res.data.data });
            dispatch( getApplicationInfo(application_id) );
            dispatch( 
                notify( res.data.data.message, "success" )
            );
        });
}


export const SIGN_APPS_REQ =  "SIGN_APPS_REQ";
export const SIGN_APPS_RES =  "SIGN_APPS_RES";

export const multisignAgreements = ( applications, callback_selected_fn ) => dispatch => {

    dispatch({ type: SIGN_APPS_REQ });
    axios.post(`/api/reporting/multi-sign-grunts`, {
        applications
    })
    .then( res => {
        console.log( res.data.data );
        dispatch({ type: SIGN_APPS_RES, payload: res.data.data });
        dispatch( searchAgreementApplications(1) );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        callback_selected_fn( [] );
    });   

}


export const SIGN_ADDITIONAL_AGREEMENTS_REQ =  "SIGN_ADDITIONAL_AGREEMENTS_REQ";
export const SIGN_ADDITIONAL_AGREEMENTS_RES =  "SIGN_ADDITIONAL_AGREEMENTS_RES";

export const multisignAdditionalsAgreements = ( applications_ids, additional_ids, callback_selected_fn ) => dispatch => {

    dispatch({ type: SIGN_ADDITIONAL_AGREEMENTS_REQ });
    axios.post(`/api/reporting/multi-sign-additionals`, {
        applications: applications_ids,
        additional_ids: additional_ids
    })
    .then( res => {
        console.log( res.data.data );
        dispatch({ type: SIGN_ADDITIONAL_AGREEMENTS_RES, payload: res.data.data });
        dispatch( searchAgreementApplications('', 'additional') );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        callback_selected_fn( [] );
    });   

}


export const SIGN_COMPLETED_ACTS_REQ =  "SIGN_COMPLETED_ACTS_REQ";
export const SIGN_COMPLETED_ACTS_RES =  "SIGN_COMPLETED_ACTS_RES";

export const multisignActs = ( applications_ids, callback_selected_fn ) => dispatch => {
    dispatch({ type: SIGN_COMPLETED_ACTS_REQ });
    axios.post(`/api/reporting/multi-sign-acts`, {
        applications: applications_ids,
    })
    .then( res => {
        dispatch({ type: SIGN_ADDITIONAL_AGREEMENTS_RES, payload: res.data.data });
        dispatch( searchAgreementApplications('', 'act') );
        dispatch( 
            notify( res.data.data.message, "success" )
        );
        callback_selected_fn( [] );
    });   
}


export const SET_AGREEMENT_SIGNED = "SET_AGREEMENT_SIGNED";

export const setAgreementSigned = ( sign_id ) => dispatch => {

    dispatch({
        type: SET_AGREEMENT_SIGNED,
        payload: {
            sign_id
        }
    })

}

