import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDocument, notify } from '../../../../actions';
import { Button } from '../../../library';


const natural_fields = [
    'full_applicant_name', 'name_in_genitive_case', 'user_name',
    'passport', 'passport_by', 'rnokpp', 'registration_address', 'post_address',
    'person_getter', 'bank_account', 'bank_name', 'bank_code', 
    'payment_info', 'user_phone', 
];

const legal_fields = [
    'name_in_genitive_case', 'works_by_document',
    'legal_name', 'legal_address', 'post_address', 'edropu', 
    'bank_name', 'bank_account', 'user_phone', 'user_name', 'applicant_position'
];

const fundDefaultData = {
    'name_in_genitive_case' : "", 
    'works_by_document' : "",
    'legal_name' : "Український культурний фонд", 
    'legal_address' : "01601, м. Київ, вул. І. Франка, 19", 
    'post_address' : "01010, м. Київ, вул. Лаврська, 10-12", 
    'edropu' : "41436842", 
    'bank_details' : "", 
    'bank_name' : "ГУДКСУ у м. Києві", 
    'bank_account' : "UA608201720343110001000000458", 
    'user_phone' : "+38 (044) 504-22-66", 
    'user_name' : "В.Г. Берковський", 
    'applicant_position' : "Виконавчий директор",
    
}


export const ApplicantInfo = ({ document, setDocument }) => {
    const dispatch = useDispatch();

    const [ applicantFileds, setApplicantFields ] = useState({});
    const [ fundFileds, setFundFields ]= useState({});
    const [ rework_message, setReworkMessage ] = useState("");
    const [ display_rework_aside, setReworkAside ] = useState(false);

    const labels = {
        'passport': "Паспорт", 
        'passport_by': "Паспорт виданий", 
        'rnokpp': "РНОКПП", 
        'registration_address': "Місце реєстрації", 
        'post_address': "Поштова адреса:",
        'person_getter': "Одержувач:", 
        'bank_account': "Р/р:", 
        'bank_name': "Банк одержувача:", 
        'bank_code': "МФО:", 
        'payment_info': "Призначення платежу:", 
        'user_phone': "Тел.:", 
        'user_name': "ПІБ (Підпис)",
        // ---
        'legal_name': 'Юридична назва',
        'legal_address': 'Юридична адреса',
        'edropu': 'ЄДРПОУ',
        'bank_details': 'Банківські реквізити',
        'name_in_genitive_case': 'ПІБ у родовому відмінку',
        'works_by_document' : document.is_fop ? "Виписка ЕДР (дата запису: ХХ.ХХ.ХХХХ, номер запису: ХХХХХХХХХ)" : "На основі чого діє компанія («що діє на підставі …»)",
        "applicant_position": "Посада (Підпис)",
        "full_applicant_name": "Повне ПІБ у називному відмінку",
    }

    useEffect(() => {
        
        console.log('doc', document, document.fin_details_fund );
        if( document.fin_details_fund ){
            setFundFields( document.fin_details_fund );
            console.log('SETED FUND');
        } else {
            setFundFields( fundDefaultData );
            console.log('SETUP DEFAULT');
        }

        if( document.fin_details_client ){
            setApplicantFields( document.fin_details_client );
            console.log('SETED APPLICANT');
        }
    
    }, [])
    
    const handleApplicant = ( e ) => {
        setApplicantFields({
            ...applicantFileds,
            [e.target.name]: e.target.value
        });
    }

    const handleFund = ( e ) => {
        setFundFields({
            ...fundFileds,
            [e.target.name]: e.target.value
        });
    }

    const SubmitUpdateDocument = () => {
        updateDocument( document._id, { 
            fin_details_client: applicantFileds,
            fin_details_fund: fundFileds,    
        })
        .then( res => {
            console.log('res', res );
            setDocument( res.data.data.document );
            dispatch( notify( 'Реквізити оновлено', 'success') );
        });
    }

    const ToggleReworkAside = () => {
        setReworkAside( !display_rework_aside);
    }
    
    const UpdateDocumentValidation = ( status ) => () => {
        updateDocument( document._id, { 
            fin_details_status: status,
            rework_message: rework_message,
        })
        .then( res => {
            console.log('res', res );
            setDocument( res.data.data.document );
            dispatch( notify( 'Статус валідації оновлено', 'success') );
            setReworkAside( false );
        });
    }

    let fields = [];

    if( document.bank_details_template === "natural_person"){
        fields = natural_fields;
    }
    if( document.bank_details_template === "legal_entity"){
        fields = legal_fields;
    }

    return(
        <div className="documents__page">

            <div className={ display_rework_aside ? "fin__popup" : "fin__popup hide"}>
                <div 
                    className="fin__popupBackwrap"
                    onClick={ToggleReworkAside}    
                ></div>
                <div className="fin__popupBody">
                    <h2>Напишіть коментар заявнику, що потрібно виправити у реквізитах</h2>
                    
                    <label className="expForm__item">
                        <div className="expForm__container">
                            <textarea 
                                className="form-input" 
                                rows="15" 
                                name="budget_info" 
                                value={rework_message}
                                onChange={ (e) => setReworkMessage( e.target.value ) }
                            />
                        </div>
                    </label>

                    <Button 
                        handler={ UpdateDocumentValidation(-1) } 
                        color="red"
                        text="Відправити на доопрацювання" 
                    /> 
                </div>

            </div>

            <header className="documents__header vertical">
                <div className="documents__row">
                    <Button 
                        handler={ SubmitUpdateDocument } 
                        size="small" 
                        text="Оновити реквізити" 
                    /> 
                    
                    <div className="fin_details__validationContainer">

                        <Button 
                            handler={ ToggleReworkAside } 
                            size="small" 
                            text="Доопрацювання" 
                            color="red"
                            disabled={  document.fin_details_status === -1 || document.fin_details_status === 2 }
                        /> 

                        <Button 
                            handler={ UpdateDocumentValidation(2) } 
                            size="small" 
                            text="Валідація" 
                            color="green"
                            disabled={  document.fin_details_status === -1 || document.fin_details_status === 2 }
                        /> 

                        <Button 
                            handler={ UpdateDocumentValidation(0) } 
                            size="small" 
                            text="Відкотити статус" 
                            color="gray"
                        /> 

                        <div className="fin_details__status">
                            Статус: <br/>
                            { document.fin_details_status === 0 && <span className="text-gray">⬤ Не оброблено</span> }
                            { document.fin_details_status === -1 && <span className="text-red">⬤ Відправлено на доопрацювання </span> }
                            { document.fin_details_status === 2 && <span className="text-green">⬤ Валідовано </span> }
                            { document.fin_details_status === 1 && <span className="text-yellow">⬤ Надіслано на перевірку </span> }
                        </div>

                    </div>
                </div>                

                <div className="documents__row">
                    <div className="documents__form documents__form-finDetails">
                        <h2> Реквізити заявника </h2>
                    </div>
                    <div className="documents__form documents__form-finDetails">
                        <h2> Реквізити Фонду </h2>
                    </div>
                </div>
            </header>  
            <div className="singleExpert__container">
                <div>
                    <b>Шаблон реквізитів:</b>
                    { document.bank_details_template === "natural_person" && "Фізична особа (Стипендії)" }
                    { document.bank_details_template === "legal_entity" && "Юридична особа" }
                </div>
            </div>

            {
                document.fin_details_status === -1 && (
                    <div style={{ marginBottom: "20px"}}>
                        <b>Повідомлення валідації</b>
                        <div style={{ whiteSpace: "pre-line" }}>
                            {document.rework_message}
                        </div>
                    </div>
                )
            }

            <div className="documents__row">
                <div className="documents__form documents__form-finDetails">
                    {/* <h2> Реквізити заявника </h2> */}
                    {
                        fields.map( (field, index) => {

                            let value = "";

                            if( document.fin_details_client && document.fin_details_client.hasOwnProperty( field )   ){
                                value = document.fin_details_client[field];
                            }

                            return(
                                <label key={`${field}_${index}`}>
                                    <div>{labels[field]}</div>
                                    <input 
                                        className="documents__input"
                                        name={field}
                                        defaultValue={ value }
                                        onChange={handleApplicant}
                                    />
                                </label>
                            );
                        })
                    }
                </div>

                <div className="documents__form documents__form-finDetails">
                    {/* <h2> Реквізити Фонду </h2> */}
                    {
                        legal_fields.map( (field, index) => {
                            if( index === 0 || index === 1 ){
                                return null;
                            }
                            let value = "";

                            if( fundFileds[ field ] ){
                                value = fundFileds[ field ];
                            } else {
                                value = fundDefaultData[ field ];
                            }
                            

                            return(
                                <label key={`${field}_${index}`}>
                                    <div>{labels[field]}</div>
                                    <input 
                                        className="documents__input"
                                        name={field}
                                        value={ value }
                                        onChange={ handleFund }
                                    />
                                </label>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )


};